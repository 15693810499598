填报表单
<template>
  <v-dialog
    v-model="isShow"
    max-width="800px"
  >
    <v-card style="height: 100%">
      <v-toolbar
        color="info"
        dark
      >
        <v-toolbar-title>项目推进情况</v-toolbar-title>
        <v-spacer />
        <v-btn
          color="#0D47A1"
          @click="saveBtn()"
        >
          保存
        </v-btn>
      </v-toolbar>
      <template v-if="formLoading">
        <v-progress-circular
          :width="3"
          color="green"
          indeterminate
        />
      </template>
      <form-create
        v-model="formCreateRule.fApi"
        class="pa-4 pb-0"
        :rule="formCreateRule.rule"
        :option="formCreateRule.option"
      />
    </v-card>
  </v-dialog>
</template>
<script>
  import _ from 'lodash';
  import FcEditor from '@form-create/component-wangeditor';
  import Vue from 'vue';
  import Upload from './component/Upload';
  export default {
    components: {
      // Upload: Upload,
      files: () => import('./component/Files.vue'),
    },
    data: () => ({
      uploadList: [],
      formLoading: true, // 表单加载圈
      // 项目阶段选择下拉菜单数据源
      stages: [],
      formId: 'c5c289be-3cd1-4f71-96bc-aeff488051fc',
      breadcrumbs: [
        // 面包屑导航
        {
          text: '首页',
          disabled: false,
        },
        {
          text: '编辑',
          disabled: true,
        },
      ],
      formCreateRule: {
        // 表单创建规则
        // 实例对象
        fApi: {},
        // 表单生成规则
        rule: [],
        // 组件参数配置
        option: {
          submitBtn: false,
          form: {
            labelWidth: undefined,
            labelPosition: 'top',
          },
          row: {
            gutter: 30,
          },
        },
      },
      projectId: null,
      map: null,
      files: {},
      isAdmin: false,
      isShow: false,
      planItem: {},
      // 外商
      wsFormId: 'bce0f632-42a8-4cf7-a30c-e5c6f00c6e62',
      // 推介
      tjFormId: 'defdcd74-fe79-4906-b3aa-dbdb0ce55afe',
      isXs: false,
    }),
    watch: {
      // 监听路由是否变化，一旦变化重新设置表单id和拉取项目列表
      $route: {
        handler (to, from) {
          // this.createForm(this.formId, this.projectId)
          console.log('有变化');
        },
        deep: true,
        // 代表在wacth里声明了这个方法之后立即先去执行handler方法
        immediate: true,
      },
      '$vuetify.breakpoint.name': {
        handler (name) {
          if (name === 'xs') {
            this.isXs = true
          } else if (name === 'sm') {
            this.isXs = false
          } else {
            this.isXs = false
          }
        },
        deep: true,
        // 代表在wacth里声明了这个方法之后立即先去执行handler方法
        immediate: true,
      },
    },
    async created () {
      this.getFormsSelect();
    },
    mounted () {
      this.$nextTick(async () => {
        await this.createForm(this.formId, this.projectId);
      });
      Vue.component('uploadFile', Upload);
      Vue.component('uploadFiles', Upload);
      Vue.component('editor', FcEditor);
      Vue.component('Dividers', {
        props: ['value'],
        template: '<Divider plain>{{value}}</Divider>',
      });
      Vue.component('treeselect', {
        props: ['data', 'value'],
        watch: {
          value (val) {
            console.log('val', val);
            // const labels = []
            // val.map(val => {
            //   labels.push(val.label)
            // })
            this.$emit('input', val);
          },
        },
        template:
          "<a-tree-select :replace-fields=\"{ title: 'name', value: 'id' }\" :dropdown-style=\"{ maxHeight: '58vh' }\" v-model='value'  show-search tree-default-expand-all allow-clear tree-node-filter-prop='title' :tree-check-strictly='true' :tree-data='data' />",
      });
    },
    methods: {
      async open (item) {
        this.planItem = item;
        this.$nextTick(async () => {
          await this.createForm(this.formId, this.projectId);
        });
        this.isShow = true;
      },
      async getFormsSelect () {
        const forms = await this.$axios.get('/forms');
        const formData = await this.$axios.get(`/forms/${this.formId}`);
        const formType = formData.data.data.categoryId;
        this.stages = forms.data.data.filter((val) => {
          return formType === val.categoryId;
        });
        this.stages.sort((a, b) => {
          return a.weight - b.weight;
        });
      },
      // 创建表单
      async createForm (formId, projectId) {
        // 赋值表单的创建规则
        this.formLoading = false; // 隐藏加载图标
        const formRule = await this.formRule(formId); // 获取表单规则
        const ruleFormat = await this.ruleFormat(formRule); // 过滤表单规则
        this.formCreateRule.rule = ruleFormat; // 将过滤后的规则赋值给表单组件
        const formData = await this.inputValue(projectId); // 获取项目数据
        console.log('rule', this.formCreateRule.rule);

        await this.initFormData(formData, formRule, formId);
      },
      // 获取创建表单的原始规则
      async formRule (formId) {
        if (formId) {
          const formData = await this.$axios.get('/forms/' + formId + '/fields');
          return formData.data.data;
        }
      },
      // 创建表单规则，参数1：表单规则
      async ruleFormat (formRule) {
        const that = this;
        // 规则存放数组
        const rule = [];
        for (let i = 0; i < formRule.length; i++) {
          var obj = {};
          obj.title = formRule[i].name;
          // 字段名称
          obj.field = formRule[i].code;
          // 字段排序
          obj.weight = formRule[i].weight;
          if (formRule[i].config) {
            obj.col = this.isXs ? { span: 24 } : formRule[i].config.col || { span: 24 };
            obj.props = formRule[i].config.props || {};
          } else {
            obj.col = { span: 24 };
            obj.props = {};
          }
          // 验证规则
          obj.validate = [];
          // 判断验证规则有哪些
          if (formRule[i].rules.includes('required')) {
            obj.validate.push({
              required: true,
              message: '此项必填!',
            });
          }
          if (formRule[i].rules.includes('numeric')) {
            obj.props.number = true;
            obj.validate.push({
              type: 'number',
              message: '此项仅限数值!',
            });
          }
          // 表单默认值
          obj.props.placeholder = formRule[i].placeholder;
          switch (
            formRule[i].type // 判断表单类型
          ) {
            case 'rich-textarea':
              obj.type = 'editor';
              obj.props.init = (editor) => {
                editor.config.height = 500;
                editor.config.uploadImgMaxLength = 100;
                editor.config.uploadVideoMaxSize = 1 * 10 * 1024 * 1024 * 1024;
                editor.config.uploadImgMaxSize = 1 * 100 * 1024 * 1024;
                editor.config.customUploadImg = async (
                  resultFiles,
                  insertImgFn,
                ) => {
                  const formData = new window.FormData();
                  for (var i = 0; i < resultFiles.length; i++) {
                    formData.append('file', resultFiles[i]);
                  }
                  const res = await that.$axios.post('/files', formData, {
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                  });
                  insertImgFn(res.data.data.url);
                };
                editor.config.customUploadVideo = async function (
                  resultFiles,
                  insertVideoFn,
                ) {
                  const formData = new window.FormData();
                  for (var i = 0; i < resultFiles.length; i++) {
                    formData.append('file', resultFiles[i]);
                  }
                  const resp = await that.$axios.post('/files', formData, {
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                  });
                  insertVideoFn(resp.data.data.url);
                };
              };

              break;
            // 多行文本类型
            case 'textarea':
              obj.type = 'input';
              break;
            case 'combobox':
              obj.type = 'autoComplete';
              obj.value = '';
              obj.props.data = await this.getAutoCompleteDictData(
                formRule[i].dictId,
              );
              break;
            case 'number':
              obj.type = 'InputNumber';
              obj.props.width = '100%';
              obj.props.min = 0;
              obj.value = '';
              // 内资
              if (formRule[i].code === 'rmbMonth') {
                obj.on = {
                  'on-change': () => {
                    const rmbMonth =
                      this.formCreateRule.fApi.getValue('rmbMonth');
                    if (rmbMonth && rmbMonth !== null && rmbMonth !== '') {
                      this.formCreateRule.fApi.setValue(
                        'rmbInPlace',
                        Number(rmbMonth) + Number(this.rmbInPlace),
                      );
                    } else {
                      this.formCreateRule.fApi.setValue('rmbInPlace', '');
                    }
                  },
                };
              // 外资
              } else if (formRule[i].code === 'dollarMonth') {
                obj.on = {
                  'on-change': () => {
                    const dollarMonth =
                      this.formCreateRule.fApi.getValue('dollarMonth');
                    // console.log(dollarMonth)
                    if (
                      dollarMonth &&
                      dollarMonth !== null &&
                      dollarMonth !== ''
                    ) {
                      this.formCreateRule.fApi.setValue(
                        'dollarInPlace',
                        Number(dollarMonth) + Number(this.dollarInPlace),
                      );
                    } else {
                      this.formCreateRule.fApi.setValue('dollarInPlace', '');
                    }
                  },
                };
              } else if (formRule[i].code === 'ranking' && !this.projectId) {
                this.formCreateRule.fApi.hidden(true, 'ranking');
              }
              break;
            case 'hidden':
              obj.type = 'hidden';
              break;
            case 'date':
              obj.type = 'DatePicker';
              if (formRule[i].code === 'onYear') {
                obj.props.type = 'year';
                if (!this.projectId) {
                  this.formCreateRule.fApi.hidden(true, 'onYear');
                }
              }
              break;
            case 'select':
              obj.type = 'select';
              obj.props.clearable = true;
              if (
                formRule[i].code === 'progress' &&
                this.planItem.formId === this.tjFormId
              ) {
                obj.options = await this.getSelectDictData(
                  'eccd42d9-a0e3-41b6-bb29-05142e0753d2',
                );
              } else {
                obj.options = await this.getSelectDictData(formRule[i].dictId);
              }
              break;
            case 'selects':
              obj.type = 'select';
              obj.width = '100%';
              obj.props.multiple = true;
              obj.options = await this.getSelectDictData(formRule[i].dictId);
              break;
            case 'radiobox':
              obj.type = 'radio';
              obj.options = await this.getSelectDictData(formRule[i].dictId);
              break;
            case 'switch':
              obj.type = 'switch';
              obj.props = {
                trueValue: 'true',
                falseValue: 'false',
              };
              if (!this.isAdmin) {
                obj.type = 'hidden';
              }
              break;
            case 'checkbox':
              obj.type = 'checkbox';
              obj.options = await this.getSelectDictData(formRule[i].dictId);
              break;
            case 'cascader':
              obj.type = 'cascader';
              obj.value = [];
              if (obj.field === 'city') {
                obj.props.data = await this.getTreeDictData(
                  formRule[i].dictId,
                  '370000',
                );
              } else {
                obj.props.data = await this.getTreeDictData(
                  formRule[i].dictId,
                  '0',
                );
              }
              break;
            case 'tree':
              obj.type = 'treeselect';
              // obj.props.multiple = true
              // obj.props.showCheckbox = true
              if (formRule[i].code === 'name') {
                obj.props.data = await this.getUserGroupTree();
              } else {
                obj.props.data = await this.getTreeDictData(
                  formRule[i].dictId,
                  '0',
                );
              }
              break;
            case 'file':
              obj.type = 'uploadFile';
              obj.props.value = ['0000'];
              break;
            case 'files': {
              obj.type = 'uploadFiles';
              if (obj.field === 'opinion') {
                obj.props.imgIcon = 'md-document';
              }

              break;
            }
            default:
              obj.type = 'input';
              if (obj.field === 'articleId') {
                obj.props.disabled = 'true';
              }
          }
          rule.push(obj);
        }
        // console.log(rule)
        return rule;
      },
      // 获取自动补全数据
      async getAutoCompleteDictData (dictId) {
        const select = [];
        if (dictId) {
          await this.$axios.get(`/dictionaries/${dictId}`).then((res) => {
            const resData = res.data.data;
            resData.map((val) => {
              select.push(val.code);
            });
          });
        }
        return select;
      },
      // 获取选择列表数据
      async getSelectDictData (dictId) {
        const select = [];
        await this.$axios.get(`/dictionaries/${dictId}`).then((res) => {
          const resData = res.data.data;
          resData.map((val) => {
            if (val.name === '吕涛书记重点调度项目') {
              select.push({
                value: val.code,
                label: val.name,
              });
            } else {
              select.push({
                value: val.code,
                label: val.name,
              });
            }
          });
        });
        return select;
      },
      async getUserGroupTree () {
        const res = await this.$axios.get('/dictionaries/roles');
        const resData = res.data.data;
        this.allGroupTree = resData;
        var userGroupTree = [];
        await resData.map(async (val) => {
          val.cid = val.id;
          // 推送园区为父级
          if (val.id === localStorage.orgId) {
            this.parentOrgId = val.id;
            userGroupTree = await this.treeData(resData, val.id);
            userGroupTree.sort((a, b) => {
              return a.weight - b.weight;
            });
          }
        });
        return userGroupTree;
      },
      // 获取树形数据1
      async getTreeDictData (dictId, cityId, type) {
        let tree = [];
        if (dictId) {
          await this.$axios.get(`/dictionaries/${dictId}`).then((res) => {
            const resData = res.data.data;
            resData.map((val) => {
              val.cid = val.id;
              // 级联菜单
              val.value = val.code;
              val.label = val.name;
              // 树状菜单
              val.title = val.name;
              val.id = val.code;
            });
            tree = this.treeData(resData, cityId);
          });
        }
        return tree;
      },
      // 格式化用户组数据返回树形结构
      treeData (sourceData, id) {
        const cloneData = JSON.parse(JSON.stringify(sourceData));
        return cloneData.filter((father) => {
          const branchArr = cloneData.filter(
            (child) => father.id === child.parentId,
          );
          branchArr.length > 0
            ? (father.children = branchArr)
            : (father.children = []);
          return father.parentId + '' === id + '';
        });
      },
      // 初始化表单数据
      async initFormData (formData, formRule, formId) {
        const rule = await this.ruleFormat(formRule);
        if (formData) {
          for (const key in formData) {
            rule.map((val) => {
              if (
                val.field === key &&
                (val.type === 'select' ||
                  val.type === 'selects' ||
                  val.type === 'checkbox' ||
                  val.type === 'cascader' ||
                  val.type === 'treeselect' ||
                  val.type === 'tree' ||
                  val.type === 'file' ||
                  val.type === 'files' ||
                  val.type === 'uploadFile' ||
                  val.type === 'uploadFiles' ||
                  (val.type === 'DatePicker' && val.props.type === 'daterange'))
              ) {
                if (formData[key]) {
                  const formD =
                    Array.isArray(formData[key]) && formData[key]
                      ? formData[key]
                      : formData[key].split(',');
                  if (key !== 'attachment') {
                    this.formCreateRule.fApi.setValue(key, formD);
                  } else {
                    this.formCreateRule.fApi.setValue(key, formD);
                  }
                }
              } else if (val.field === key && val.type === 'InputNumber') {
                if (isNaN(formData[key])) {
                  this.formCreateRule.fApi.setValue(key, formData[key]);
                }
              } else if (val.field === key && val.type === 'switch') {
                this.formCreateRule.fApi.setValue(key, formData[key]);
              } else if (val.field === key) {
                let data = formData[key];
                for (let i = 0; i < val.validate.length; i++) {
                  if (val.validate[i].type && val.validate[i].type === 'number') {
                    data = parseInt(formData[key]);
                    break;
                  }
                }
                this.formCreateRule.fApi.setValue(key, data);
              }
            });
          }
        }
      },
      // 获取项目信息
      async inputValue (projectId) {
        // 判断是否有项目ID，新增项目是没有项目ID的
        if (projectId) {
          const projectInfo = await this.$axios.get(
            `/reports/${projectId}/detail`,
          );
          return projectInfo.data.data.data;
        } else {
          return null;
        }
      },
      // 保存按钮
      saveBtn: _.throttle(
        function () {
          this.formCreateRule.fApi.validate((valid) => {
            if (valid) {
              this.saveData(this.formId, this.projectId);
            } else {
              this.$swal({
                title: '表单验证未通过',
                text: '请查看红色表单项与提示',
                icon: 'warning',
                confirmButtonText: '知道了',
              });
            }
          });
        },
        1500,
        { trailing: false },
      ),
      // 提交按钮
      submitBtn: _.throttle(
        function () {
          this.formCreateRule.fApi.validate((valid) => {
            if (valid) {
              this.submitData(this.formId, this.projectId);
            } else {
              this.$swal({
                title: '表单验证未通过',
                text: '请查看红色表单项与提示',
                icon: 'warning',
                confirmButtonText: '知道了',
              });
            }
          });
        },
        1500,
        { trailing: false },
      ),
      // 格式化表单数据
      async filterFormData () {
        let ysFormData = null;
        this.formCreateRule.fApi.submit(async (formData) => {
          ysFormData = formData;
        });
        for (const data in ysFormData) {
          if (typeof ysFormData[data] === 'string') {
            if (ysFormData[data] === '') {
              ysFormData[data] = null;
            }
          } else if (ysFormData[data] instanceof Array) {
            if (ysFormData[data].length === 0) {
              ysFormData[data] = null;
            } else if (ysFormData[data][0] === '') {
              ysFormData[data] = null;
            } else {
              ysFormData[data] = ysFormData[data].toString();
            }
          }
        }

        return ysFormData;
      },
      async patProjectStatus2 (id, progress) {
        const resp = await this.$axios.patch(`/reports/${id}`, { projectStatus2: progress });
        console.log('resp', resp);
      },
      async patProjectStatus3 (id) {
        const resp = await this.$axios.patch(`/reports/${id}`, { projectTime2: this.$moment().format('YYYY-MM-DD HH:mm:ss') });
        console.log('resp', resp);
      },
      // 保存表单数据
      async saveData (formId, id) {
        const isMarket = localStorage.isMarket === 'true' || localStorage.isMarket === true
        const orgQX = localStorage.orgQX === 'true' || localStorage.orgQX === true
        var requert = null
        const filterFormData = await this.filterFormData();
        // 项目进度字段存在，对比更新项目中项目状态
        if (filterFormData.progress) {
          if (!this.planItem.data.projectStatus2) {
            this.patProjectStatus2(this.planItem.id, filterFormData.progress);
          } else {
            var list
            if (this.planItem.formId === this.wsFormId) {
              list = await this.getSelectDictData(
                'ba99ae56-7c5a-4fc0-98f0-93ea65c88878',
              );
              console.log('list', list, '父级状态', this.planItem.data.projectStatus2, '项目进度', filterFormData.progress);
            } else if (this.planItem.formId === this.tjFormId) {
              list = await this.getSelectDictData(
                'eccd42d9-a0e3-41b6-bb29-05142e0753d2',
              );
              console.log('list2', list, '父级状态', this.planItem.data.projectStatus2, '项目进度', filterFormData.progress);
            }
            var listsort1 = 0
            var listsort2 = 0
            for (let inx = 0; inx < list.length; inx++) {
              if (list[inx].value === filterFormData.progress) {
                console.log('inx', inx);
                listsort1 = inx
              }
              if (list[inx].value === this.planItem.data.projectStatus2) {
                console.log('inx2', inx);
                listsort2 = inx
              }
            }
            console.log('Number(listsort1)', Number(listsort1), Number(listsort2));

            // 项目进展大于项目状态时
            if (Number(listsort1) > Number(listsort2)) {
              this.patProjectStatus2(this.planItem.id, filterFormData.progress);
              // 项目状态不存在于项目进展中  并且项目进展处于第一个
            } else if ((Number(listsort1) === Number(listsort2)) && Number(listsort2) === 0) {
              this.patProjectStatus2(this.planItem.id, filterFormData.progress);
            }
          }
        }
        console.log('filterFormData', filterFormData);

        filterFormData.name = this.planItem.name + ',' + localStorage.userName
        filterFormData.parentName = this.planItem.name
        filterFormData.parentFormId = this.planItem.formId
        filterFormData.parentID = this.planItem.id
        // 区县加个字段区分
        if (orgQX && !isMarket && this.planItem.formId === this.wsFormId) {
          filterFormData.isOrg = '是'
        }
        console.log('filterFormData', filterFormData);
        const weight = filterFormData.weight ? filterFormData.weight : 0
        if (!id) {
          requert = this.$axios.post(`/forms/${formId}/reports3`, {
            data: filterFormData,
            weight: weight,
            parentId: this.planItem.id,
          })
        } else {
          requert = this.$axios.put(`/reports/${id}/update3`, {
            data: filterFormData,
            weight: weight,
            parentId: this.planItem.id,
          })
        }
        requert.then(async (res) => {
          this.$swal({
            title: '保存成功!',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500,
          })
          // 项目状态每次更新后项目状态时间就更新
          this.patProjectStatus3(this.planItem.id);
          this.$emit('getchild')
          this.isShow = false
        })
          .catch((err) => {
            console.log(err)
            this.$swal({
              title: '保存失败!',
              icon: 'error',
            })
          })
      },
      // 提交表单数据
      async submitData (formId, id) {
        let requert = null;
        const filterFormData = await this.filterFormData();
        const weight = filterFormData.weight ? filterFormData.weight : 0;
        if (!id) {
          requert = this.$axios.post(`/forms/${formId}/reports3`, {
            data: filterFormData,
            weight: weight,
          });
        } else {
          requert = this.$axios.put(`/reports/${id}/update3`, {
            data: filterFormData,
            weight: weight,
          });
        }
        requert
          .then((res) => {
            let projectId = null;
            if (id) {
              projectId = id;
            } else {
              projectId = res.data.data.id;
            }
            this.$axios
              .put(`/reports/${projectId}/submit`)
              .then((res) => {
                this.$swal({
                  title: '提交成功!',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1500,
                });
              })
              .catch((err) => {
                console.log(err);
                this.$swal({
                  title: '提交失败!',
                  icon: 'error',
                });
              });
          })
          .catch((err) => {
            console.log(err);
            this.$swal({
              title: '提交失败!',
              icon: 'error',
            });
          });
      },
    },
  };
</script>
<style lang="scss" scoped>
.ivu-date-picker {
  display: block;
}
::v-deep .ivu-input-number {
  width: 100%;
}
.mapDiv {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 500px;
  touch-action: pan-y;
}
.mapEditBox {
  text-align: center;
}
/* #regular-tables{
  position: relative;
}; */
/* #mapBox{
  position: absolute;
  left: -9999px;
} */
.amap-icon img,
.amap-marker-content img {
  width: 25px;
  height: 34px;
}

.marker {
  position: absolute;
  top: -20px;
  right: -118px;
  color: #fff;
  padding: 4px 10px;
  box-shadow: 1px 1px 1px rgba(10, 10, 10, 0.2);
  white-space: nowrap;
  font-size: 12px;
  font-family: '';
  background-color: #25a5f7;
  border-radius: 3px;
}

.input-card {
  width: 18rem;
  z-index: 170;
}

.input-card .btn {
  margin-right: 0.8rem;
}

.input-card .btn:last-child {
  margin-right: 0;
}
::v-deep .v-dialog {
  height: auto;
}
</style>
